import React from "react"
import { Main, Wrapper } from "./style"

const TextComponent = ({ children }) => {
  return (
    <Main>
      <Wrapper>{children}</Wrapper>
    </Main>
  )
}

export default TextComponent
