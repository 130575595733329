import styled from "styled-components"

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

  &:nth-of-type(4) {
    background: #111111;
  }
`

export const Wrapper = styled.div`
  width: 83.3%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.88%;
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const LeftSide = styled.div`
  height: auto;
  order: ${props => (props.imageFirst ? "2" : "1")};
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: ${props =>
    props.imageFirst ? "20px 0 20px 30px" : "20px 30px 20px 0"};

  h1,
  h2,
  h3 {
    margin-bottom: 10px;
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 2.55rem;
    letter-spacing: 0.04em;
    background: linear-gradient(
      90.75deg,
      #bb913c -4.54%,
      #eae69c 43.88%,
      #ce9638 101.11%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.1rem;
    letter-spacing: 0;
    color: #fdfdfd;
  }

  h4,
  h5 {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.1rem;
    letter-spacing: 0;
    color: #fdfdfd;
  }

  @media (max-width: 799px) {
    width: 100%;
    order: ${props => (props.imageFirst ? "1" : "1")};
    padding: 20px 0;
  }
`

export const RightSide = styled.div`
  height: 450px;
  order: ${props => (props.imageFirst ? "1" : "2")};
  width: calc(50% - 10px);
  display: flex;
  justify-content: ${props => (props.imageFirst ? "flex-start" : "flex-end")};
  background-image: url(${props => props.image});
  z-index: 2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1600px) {
    height: 370px;
  }

  @media (max-width: 1400px) {
    height: 325px;
  }

  @media (max-width: 1250px) {
    height: 280px;
  }

  @media (max-width: 1149px) {
    background-size: cover;
    height: 275px;
    background-position: center;
  }

  @media (max-width: 900px) {
    width: 50%;
    height: 300px;
  }

  @media (max-width: 799px) {
    width: 100%;
    order: ${props => (props.imageFirst ? "2" : "2")};
    background-size: contain;
    background-position: center;
  }
`
