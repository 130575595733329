import React from "react"
import { Main, LeftSide, RightSide, Wrapper } from "./style"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const SecondSection = ({ image, children }) => {
  return (
    <Main>
      <Wrapper>
        <LeftSide imageFirst={false}>{children}</LeftSide>

        <RightSide imageFirst={false} image={image} />
      </Wrapper>
    </Main>
  )
}

export default SecondSection
