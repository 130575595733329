import React from "react"
import { Main, LogoBox, SpanBox, Shadow, MainBochnia } from "./style"
import whiteLogo from "../../../images/white-logo.png"

const BochniaHeader = ({ contact_niepolomice, goldHeader }) => {
  return (
    <MainBochnia goldHeader={goldHeader}>
      <Shadow />
      <SpanBox>
        <LogoBox>
          <img src={whiteLogo} alt="logo" />
        </LogoBox>
        <h1>{contact_niepolomice}</h1>
      </SpanBox>
    </MainBochnia>
  )
}

export default BochniaHeader
