import React from "react"
import { Main, Wrapper } from "./style"
import GoogleMapsEmbed from "../../GoogleMapsEmbed"

const BochniaMap = ({ location }) => {
  return (
    <Main>
      <Wrapper>
        <h3>{location}</h3>
      </Wrapper>
      <GoogleMapsEmbed query={"bochnia dębosz"} />
    </Main>
  )
}

export default BochniaMap
