import styled from "styled-components"

export const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 75px;

  &:first-child {
    margin-top: 79px;
  }
`

export const Wrapper = styled.div`
  width: 83.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1,
  h2,
  h3 {
    margin-bottom: 10px;
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 2.55rem;
    letter-spacing: 0.04em;
    background: linear-gradient(
      90.75deg,
      #bb913c -4.54%,
      #eae69c 43.88%,
      #ce9638 101.11%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.1rem;
    letter-spacing: 0;
    color: #fdfdfd;
  }

  b {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.1rem;
    letter-spacing: 0;
    color: #fdfdfd;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.1rem;
    letter-spacing: 0;
    color: #fdfdfd;
  }

  h5 {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.1rem;
    letter-spacing: 0;
    color: #fdfdfd;
    display: inline;
  }

  ol {
    list-style-position: inside;
    list-style-type: decimal;
    padding-left: 10px;

    li {
      margin-top: 20px;

      p {
        margin-top: 20px;
        padding-left: 20px;
      }
    }

    h5 {
      font-weight: 400;
    }

    li::marker {
      color: #fdfdfd;
      font-size: 1.3rem;
    }
  }

  @media (max-width: 1149px) {
    width: 82.5%;
  }

  @media (max-width: 799px) {
    width: 88.88%;
  }
`
