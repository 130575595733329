import React, { useContext } from "react"
import {
  Main,
  Wrapper,
  Box1,
  Box2,
  Box3,
  ContentBox,
  TitleBox,
  TabletMain,
  TabletWrapper,
} from "./style"
import locationIcon from "../../../images/location2-icon.png"
import { ResolutionContext } from "../../../contexts/ResolutionContext"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const OfficeWithoutContentfulEN = ({
  office_niepolomice,
  debosz_nieruchomosci,
  opening_hours,
  rynek,
}) => {
  const [isTablet] = useContext(ResolutionContext)
  if (isTablet) {
    return (
      <>
        <Main>
          <Wrapper>
            <TitleBox>
              <img src={locationIcon} alt="icon" />
              <h3>{office_niepolomice}</h3>
            </TitleBox>
            <Box1>{rynek && renderRichText(rynek)}</Box1>
          </Wrapper>
        </Main>
        <TabletMain>
          <TabletWrapper>
            <Box2>
              <pre>
                {debosz_nieruchomosci && renderRichText(debosz_nieruchomosci)}
              </pre>
            </Box2>

            <Box3>{opening_hours && renderRichText(opening_hours)}</Box3>
          </TabletWrapper>
        </TabletMain>
      </>
    )
  } else {
    return (
      <Main>
        <Wrapper>
          <TitleBox>
            <img src={locationIcon} alt="icon" />
            <h3>BOCHNIA OFFICE</h3>
          </TitleBox>

          <ContentBox>
            <Box1>
              <h5>Kazimierza Wielkiego 35,</h5>
              <h5>32-700 Bochnia</h5>
              <p>
                E-mail address: <br />
                biuro@debosz.com.pl
              </p>

              <p>Nip: 6831984798 (Tax Identification Number)</p>
              <p>REGON: 383340414</p>
            </Box1>
            <Box2>
              <h5>DĘBOSZ Nieruchomości</h5>
              <p>Bank account number:</p>
              <p>41 1240 4588 1111 0010 9019 7911</p>
              <p>SWIFT/BIC: PKOPPLPW</p>
              <p>IBAN: PL</p>
            </Box2>

            <Box3>
              <h5>Opening hours:</h5>
              <p>Monday - Friday 10:00 - 17:00</p>
              <p>Phone number +48 503 047 223</p>
              <p>Phone number +48 535 573 640</p>
            </Box3>
          </ContentBox>
        </Wrapper>
      </Main>
    )
  }
}

export default OfficeWithoutContentfulEN
