import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/ContactPage/Header"
import OfficeComponent from "../../components/ContactPage/OfficeComponent"
import MapComponent from "../../components/ContactPage/MapComponent"
import { useIntl, navigate } from "gatsby-plugin-react-intl"
import TextComponent from "../../components/ContactPage/TextComponent"
import image1 from "../../images/niepolomice-image1.jpg"
import image2 from "../../images/niepolomice-image2.jpg"
import SecondSection from "../../components/ContactPage/SecondSection"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import bochniaimage from "../../images/bochnia-image.jpg"
import BochniaMap from "../../components/ContactPage/MapComponent/BochniaMap"
import BochniaHeader from "../../components/ContactPage/Header/BochniaHeader"
import OfficeWithoutContentfulEN from "../../components/ContactPage/OfficeComponent/OfficeWithoutContentfulEN"

const ImageBox = styled.div`
  width: 100%;
  aspect-ratio: 16/8;
  display: flex;
  justify-content: center;
  margin-bottom: 78px;

  img {
    width: 100%;
    height: 100%;
  }
`
const H3Box = styled.div`
  h3 {
    margin-bottom: 10px;

    font-size: 2.2rem;
    font-weight: 700;
    line-height: 3.3rem;
    letter-spacing: 0.04em;

    background: linear-gradient(
      90.75deg,
      #bb913c -4.54%,
      #eae69c 43.88%,
      #ce9638 101.11%
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const ContactPage = () => {
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (lang === "pl") {
      navigate("/kontakt/bochnia")
    }
  }, [lang])

  return (
    <Layout>
      <Seo
        title="Dębosz - 
        BOCHNIA REAL ESTATE AGENCY"
        renderSchemaLd={true}
        description="Odwiedź nasze biuro w Bochni. Nasze biuro współpracuje z najlepszymi Agentami nieruchomości!"
      />
      <BochniaHeader
        contact_niepolomice={"Bochnia REAL ESTATE AGENCY"}
        goldHeader={false}
      />
      <OfficeWithoutContentfulEN
        office_niepolomice={""}
        debosz_nieruchomosci={""}
        rynek={""}
        opening_hours={""}
      />
      <BochniaMap location={"Location:"} />

      <TextComponent>
        <h1>Real Estate Agency Bochnia</h1>
        <p>
          Are you looking for the perfect property for yourself and your family?
        </p>
        <p>Do you need support in selling real estate?</p>
        <h4>Welcome to our real estate agency!</h4>
        <p>
          We are professionals who have a deep understanding of the local real
          estate market. We are characterized by an individual approach to
          clients and full commitment at every stage of cooperation.
        </p>
        <p>
          Thanks to our many years of experience in selling houses and
          apartments, renting and buying real estate, we act quickly and
          effectively!
        </p>
        <h5>Join our group of clients!</h5>
      </TextComponent>
      <TextComponent>
        <h2>Benefit from our knowledge of real estate sales!</h2>
        <p>
          We know very well that selling real estate can take a long time.
          Finding a client who is willing to buy a house or apartment at your
          offered price is often time-consuming and labor-intensive.
        </p>
        <p>
          Our real estate agency is pleased to support you in the process of
          selling a house or apartment. Our services include preparing the
          property for sale, creating and publishing attractive listings,
          conducting presentations, as well as negotiating with potential
          buyers.
        </p>
        <p>Every property for sale is another challenge for us!</p>
        <p>
          Are you looking for professionals with years of experience in the real
          estate industry?
        </p>
        <h5>
          You've come to the right place! We invite you to cooperate with us!
        </h5>
        <h2>
          DĘBOSZ Real Estate Agency provides comprehensive support in buying and
          renting properties!
        </h2>
        <p>Are you considering buying or renting a property?</p>
        <p>
          Our real estate agency not only offers services such as support in
          buying or renting properties. Our agents also provide advice to
          clients at every stage of cooperation.
        </p>
        <p>
          So if you are considering several options (e.g., buying a property
          from the secondary market or building a house) and you are not sure
          what to choose - contact us, and together we will find the best
          solution!
        </p>
      </TextComponent>
      <ImageBox>
        <img src={bochniaimage} alt="image" />
      </ImageBox>
      <TextComponent>
        <h2>We offer comprehensive support for our clients!</h2>
        <p>
          Our range of support services is very wide. It also includes property
          financing and creating real estate promotional videos.
        </p>
        <p>
          All this is to ensure that you can finalize your transaction as
          quickly as possible and achieve full satisfaction!
        </p>
        <h5>See our services!</h5>

        <h2>
          Individual approach is not everything! Our real estate agency is
          always one step ahead of others!
        </h2>
        <p>
          DĘBOSZ Real Estate Agency stands out with its individual approach to
          clients, although it is not the only advantage of our company.
        </p>
        <p>
          We have excellent knowledge of the real estate market in the cities of
          Bochnia, Niepołomice, and the surrounding area. In our portfolio, we
          have offers for properties and plots that are true "gems".
        </p>
        <p>
          Our agents take care of clients before, during, and after the
          transaction. They ensure that buying or selling offers are as
          favorable as possible and fully meet the client's expectations.
        </p>
        <h5>Contact us!</h5>
      </TextComponent>
      <TextComponent>
        <h2>Check out our listings - there's plenty to choose from!</h2>
        <p>
          Are you looking for a plot of land up to 200,000 PLN, an apartment up
          to 300,000 PLN, or maybe a house for a maximum of 1,000,000 PLN?
        </p>
        <p>
          Our real estate agency has so many interesting offers that you will
          definitely have plenty to explore!
        </p>
        <h5>Use our search engine and find the best deals for yourself!</h5>
      </TextComponent>

      <SecondSection image={image2}>
        <h2>Is selling real estate through an agency worth it?</h2>
        <p>
          Collaborating with a real estate agency has many advantages and is
          definitely worth it!
        </p>
        <p>
          Among the most obvious benefits are safety at every stage of the
          transaction and time savings.
        </p>
        <p>
          However, there are also other aspects to consider, such as the
          possibility of reducing the purchase price of a property through
          effective negotiations with the seller and increasing the value of a
          house or apartment through professional property preparation.
        </p>
      </SecondSection>

      <TextComponent>
        <h2>Buying or renting real estate - which is better?</h2>
        <p>
          There is no definitive answer to this question because it depends on
          the client's financial capabilities, preferences, and expectations.
        </p>
        <p>
          Although it is often said that "it's better to pay for your own place
          than to pay someone else's mortgage," not everything is black and
          white.
        </p>
        <p>
          Another factor is that not everyone has the creditworthiness, and
          sometimes renting a property is the only option to live in good
          conditions.
        </p>
        <p>
          One thing is certain - among the offers of DĘBOSZ Real Estate Agency,
          you will definitely find many interesting listings for apartments and
          houses for rent or sale.
        </p>
        <h2>
          DĘBOSZ Real Estate Agency - no secrets when it comes to properties!
        </h2>

        <p>
          Are you looking for your dream apartment in Bochnia or its
          surroundings?
        </p>
        <p>
          Or perhaps you are planning to build a house and are looking for a
          plot of land?
        </p>
        <p>
          The agents representing our real estate agency will gladly take care
          of you and find the best offer for you!
        </p>
      </TextComponent>
      <TextComponent>
        <h2>
          Check out the range of services offered by DĘBOSZ Real Estate Agency -
          how can we help you?
        </h2>
        <p>
          Our real estate agency, meeting client expectations, provides a wide
          range of services.
        </p>
        <p>
          We have been working in the real estate industry for years, so we know
          that mediation in sales, purchases, or rentals is not enough.
        </p>
        <p>
          Therefore, we offer services such as financial advice and the creation
          of promotional videos.
        </p>

        <h5>
          Selling, buying, and renting real estate with our agency is pure
          pleasure!
        </h5>
      </TextComponent>
      <TextComponent>
        <H3Box>
          <h3>Let's talk about your expectations!</h3>
        </H3Box>
      </TextComponent>
    </Layout>
  )
}

export default ContactPage
